
import React from "react";
import {graphql} from 'gatsby';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Heading } from "../components/01_atoms/Heading/Heading";

import * as pages from '../components/03_organisms/Pages/pages.module.scss';
import Section from "../components/03_organisms/Section/Section";
import WorkItem from "../components/02_molecules/WorkItem/WorkItem";
import Slider from "../components/03_organisms/Slider/Slider";
import { Client } from "../components/01_atoms/Client/Client";
import { Breadcrumb } from "../components/02_molecules/Breadcrumb/Breadcrumb";
import { BreadcrumbItem } from "../components/02_molecules/Breadcrumb/BreadcrumbItem";
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";


const ClientWork = ({ data, pageContext, ...props }) => {

	// read language
	const {t} = useTranslation();

	// render html
	return (
		<>
			<HeadInject />
			<Helmet bodyAttributes={{ class: pages.work }} />

			<Section content={false}>
				<Breadcrumb>
					<BreadcrumbItem to="/work" title="Work" />
					<BreadcrumbItem to={`/work/${pageContext.slug}`} title={pageContext.name} active />
				</Breadcrumb>
			</Section>

			<Section>
				<Heading element="h1" size="xxxl" align="center">{t('clientwork_title')}</Heading>
				<Heading element="h3" size="m" uppercase align="center" font="special" weight="400"><span style={{'background': '#fff','padding': '5px'}}>{pageContext.name}</span></Heading>
			</Section>

			<Section content={false}>
      			{data.workEntry.edges.map(({ node: work },index) => (
					<WorkItem
						key={index}
						title={work.title}
						client={work.client.name}
						odd={(index%2 ? true:false)}
						link={'/work/'+work.client.slug + '/' + work.slug}
						image={work.hero[0]?.heroImage}
						alt={work.hero[0]?.heroImage && work.hero[0].heroImage.alt}
						spacing="xl"
					/>
				))}
			</Section>
			<Section fullwidth background="beige">


				<Slider title="More work we are proud of">
					{data.clientsEntry.group.map((client,index) => (
						<Client
							key={index}
							link={"/work/" + client.edges[0].node.client.slug}
							name={client.edges[0].node.client.name}
							image={client.edges[0].node.client.logo ? client.edges[0].node.client.logo.gatsbyImageData : null}
							primaryColor={client.edges[0].node.client.primaryColor ? client.edges[0].node.client.primaryColor.hex : null}
						/>
					))}
				</Slider>
			</Section>
		</>

	)
}

export default ClientWork;


export const clientWorkQuery = graphql`
query clientWorkQuery($slug: String!) {

    workEntry: allDatoCmsWork(filter: {client: {slug: {eq: $slug}}}) {
        edges {
            node {
                hero {
                    heroImage {
                      gatsbyImageData
					  format
					  url
                    }
					
                  }
                client {
                    slug
                    name
                }
                title
                slug
            }
        }
    }

	clientsEntry: allDatoCmsWork {
		group(field: client___name, limit: 1) {
		  edges {
			node {
			  client {
				name
				slug
				primaryColor {
				  hex
				}
				logo {
				  gatsbyImageData
				}
			  }
			}
		  }
		}
	  }
  }
  
  
`
